/**
 *  @copyright KARD.
 */

exports.core = {
    // stage: 'live',
    stage: 'test',
    cn: 'uk',
    // o_id: 'oblulubriggs',
    o_id: 'magnolia',

}


