/**
 * @copyright KARD
 */

import React from "react";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import ListProj from "./list_proj";
// import { ListCamp } from "./list_camp";
// import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

// import {
//     // Core,
//     DDB,
// } from '../../api/AWS'

// import Addr from '../../aui/addr'

import Page from "../_common/page";
import { Grid, LinearProgress, Modal } from "@material-ui/core";
import AuiModal from "../../aui/modal";
// import CampaignNew from "../campaign/new";
// import AuiStatus from "../../aui/status";
// import ListProj from "./list_proj";
import { useAlert } from "react-alert";

import FilterView from "./list_viewfilter";
import DonationDetailsView from "./list_viewdetails";

/**
 */
const DontList = (props) => {
  const [isModal_Filter, IsModal_Filter] = React.useState(false);
  const [isLoading, IsLoading] = React.useState(false);

  const [listDont, ListDont] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  const [isFilter, IsFilter] = React.useState(false);
  const [filterObj, FilterObj] = React.useState({
    // dateSt, dateEnd,
    // nameF, nameL,
    // addrCity, // addrCn,
    // projNo, campNo,
    // paymMethod, paymStatus,
  });

  //
  React.useEffect(() => {
    Load(null).catch((err) => {});
  }, [filterObj]);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      let resp_list = null;

      if (isFilter) {
        let dt_st = 0,
          dt_end = 0;
        let a_st = 0,
          a_end = 0;

        if (filterObj.dateSt) {
          const dt_now = new Date(filterObj.dateSt);
          dt_now.setHours(0);
          dt_now.setMinutes(0);
          dt_now.setSeconds(0);
          dt_now.setMilliseconds(0);
          dt_st = parseInt(dt_now.getTime() / 1000, 10);
        }

        if (filterObj.dateEnd) {
          const dt_now = new Date(filterObj.dateEnd);
          dt_now.setDate(dt_now.getDate() + 1);
          dt_now.setHours(0);
          dt_now.setMinutes(0);
          dt_now.setSeconds(0);
          dt_now.setMilliseconds(0);
          dt_end = parseInt(dt_now.getTime() / 1000, 10);
        }

        if (filterObj.amountSt) {
          a_st = parseInt(parseFloat(filterObj.amountSt) * 100, 10);
        }

        if (filterObj.amountEnd) {
          a_end = parseInt(parseFloat(filterObj.amountEnd) * 100, 10);
        }

        resp_list = await props.Donation_Query({
          last_key: b_more ? lastKey : null,
          // index: '',
          dt_st,
          dt_end,
          nm_f: filterObj.nameF,
          nm_l: filterObj.nameL,
          pr_n: filterObj.curProj?.tn,
          cp_n: filterObj.curCamp?.tn,
          paym_m: filterObj.paymMethod
            ? filterObj.paymMethod.toLowerCase()
            : null,
          paym_s: filterObj.paymStatus
            ? filterObj.paymStatus.toLowerCase()
            : null,
          a_st,
          a_end,
        });

        //
      } else {
        resp_list = await props.Donation_List(b_more ? lastKey : null);
      }

      console.log("DontList: Load: resp_list: ", resp_list);

      ListDont(b_more ? [...listDont, ...resp_list.Items] : resp_list.Items);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("DontList: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load    //

  //
  const CovertToReceived = async (dont) => {
    try {
      const p_dont = {
        received: true,
        tn: dont.tn,
        d: dont.d,
        status: "received",
        cp_n: dont.c,
        pr_n: dont.p,
        paym: dont.pm.m,
        amnt: dont.k && dont.k.a ? parseInt(dont.k.a * 1000, 10) : 0,
        h: dont.h,
      };

      // console.log("Donations_Internal_New: NewDonation: p_dont: ", p_dont);

      const resp_dont = await props.Donation_Received(p_dont);

      console.log(
        "Donations_Internal_New: NewDonation: resp_dont: ",
        resp_dont
      );

      let resp = true;

      resp_dont.arr_resp.map((x) => {
        if (x.msg !== "ok") resp = false;
      });

      if (resp) {
        let index = listDont.findIndex((x) => x.tn === dont.tn);
        let new_dont = listDont[index];
        new_dont.s = "received";

        if (index === -1) console.log("no match");
        else {
          ListDont([
            ...listDont.slice(0, index),
            new_dont,
            ...listDont.slice(index + 1),
          ]);
        }
      } else {
        //
      }

      return {};
    } catch (err) {
      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // CovertToReceived
  //
  const ReturnDonation = async (b_more) => {
    try {
    } catch (err) {
      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // ReturnDonation

  //
  return (
    <Page active="donation">
      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/donation.png")}
              alt=""
            />
            All Donations
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row my-3 ml-1 mr-1">
              <div className="col-sm-4 col">
                <button
                  className="btn btn btn-outline-primary mr-1 px-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // navigate("/donation/int/new");
                    IsModal_Filter(true);
                  }}
                >
                  <i className="fa fa-filter" />{" "}
                  <a className="btn-filter">
                    {isFilter ? "Filter: In Use" : "Filter: None Selected"}
                  </a>
                </button>

                <>
                  {isModal_Filter && (
                    <AuiModal
                      bOpen={isModal_Filter}
                      OnClose={() => IsModal_Filter(false)}
                    >
                      <FilterView
                        filter={filterObj}
                        OnFilter={(val) => {
                          FilterObj({ ...val });
                          IsFilter(Object.keys(val).length ? true : false);
                          // IsModal_Filter(false)
                        }}
                        OnClose={() => IsModal_Filter(false)}
                      />
                    </AuiModal>
                  )}
                </>
              </div>
              <div className="col-sm-8 col text-right">
                <button
                  className="btn btn btn-primary mr-1 px-4 font-weight-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/donation/int/new");
                  }}
                >
                  <i className="fa fa-plus" />{" "}
                  <a style={{ color: "#fff" }}>New Donation</a>
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Donor</th>
                    <th>Project</th>
                    <th>Campaign</th>
                    {/* <th>Package</th> */}
                    {/* <th>Donation Type</th> */}
                    <th>City</th>
                    <th>Amount</th>
                    <th>Method</th>
                    <th>Status</th>
                  </tr>
                </thead>

                {isLoading ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    <LinearProgress color="primary" />
                    <LinearProgress color="success" />
                    <LinearProgress color="inherit" />
                  </div>
                ) : null}

                <tbody>
                  {listDont.map((x) => (
                    <ListView
                      key={x.d}
                      dont={x}
                      {...props}
                      CovertToReceived={CovertToReceived}
                      ReturnDonation={ReturnDonation}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-sm-12 ml-3 mr-3 mt-3">
                <div className="pull-left">
                  <div className="dataTables_length">
                    {lastKey ? (
                      <button
                        className="btn btn-primary mb-3"
                        onClick={() => {
                          Load(lastKey).catch((err) => {});
                        }}
                        disabled={isLoading}
                      >
                        Show More
                      </button>
                    ) : null}
                  </div>
                </div>

                {/* <div className="pull-right mr-5">
                  <div className="dataTables_length">
                    <label className="entries">
                      Show
                      <select size="1" className="form-control show-entries">
                        <option value="10" selected="selected">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div> */}
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // DontList

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(DontList);

/**
 */
const ListView = (props) => {
  const [dontDate, DontDate] = React.useState("");
  const [dontName, DontName] = React.useState("");
  const [projName, ProjName] = React.useState("");
  const [campName, CampName] = React.useState("");
  const [donrCity, DonrCity] = React.useState("");
  const [amount, Amount] = React.useState("0.00");
  const [showDetails, ShowDetails] = React.useState(false);

  React.useEffect(() => {
    try {
      const dt_new = new Date(props.dont.tn * 1000);
      DontDate(
        [
          dt_new.getDate().toString().padStart(2, "0"),
          (dt_new.getMonth() + 1).toString().padStart(2, "0"),
          dt_new.getFullYear(),
        ].join(".")
      );
    } catch (err) {
      //
    }

    try {
      DontName(
        [
          props.dont.di.n.t.charAt(0).toUpperCase() +
            props.dont.di.n.t.slice(1),
          props.dont.di.n.f,
          props.dont.di.n.l,
        ].join(" ")
      );
    } catch (err) {
      //
    }

    try {
      ProjName(props.dont.pi && props.dont.pi.t ? props.dont.pi.t : "N/A");
    } catch (err) {
      //
    }

    try {
      CampName(props.dont.ci && props.dont.ci.t ? props.dont.ci.t : "N/A");
    } catch (err) {
      //
    }

    try {
      DonrCity(
        props.dont.di && props.dont.di.a && props.dont.di.a.c
          ? props.dont.di.a.c
          : "N/A"
      );
    } catch (err) {
      //
    }

    try {
      Amount(
        props.dont.k && props.dont.k.a ? props.dont.k.a.toFixed(2) : "0.00"
      );
    } catch (err) {
      //
    }
  }, []);

  // console.log( 'donation.list.ListView: props: ', props )

  /**
   */
  // const OnEdit = ( e ) =>
  // {
  //     e.preventDefault()
  //     e.stopPropagation()

  //     navigate( `/donation/int/new/${props.camp.tn}/${props.camp.p}` )
  // }   // OnEdit

  /**
   */
  // const OnDel = ( e ) =>
  // {
  //     e.preventDefault()
  //     e.stopPropagation()

  //     //
  // }   // OnDel
  //

  const DonationDetails = (b_more) => {
    try {
      // console.log("donation details", props.dont);
      ShowDetails(true);
    } catch (err) {
      // return Promise.reject(err);
      return { err };
    }
  }; // DonationDetails
  /**
   */
  return (
    <tr>
      {/* donation date */}
      <td
        onClick={() => DonationDetails()}
        className="text-primary"
        style={{ cursor: "pointer" }}
      >
        {dontDate}
        <DonationDetailsView
          bOpen={showDetails}
          OnClose={() => ShowDetails(false)}
          dont={props.dont}
          CovertToReceived={props.CovertToReceived}
          ReturnDonation={props.ReturnDonation}
        />
      </td>

      {/* donor name */}
      <td>{dontName}</td>

      {/* Project */}
      <td>{projName}</td>

      {/* Campaign */}
      <td>{campName}</td>

      {/* Package */}
      {/* <td>{''}</td> */}

      {/* Donation Type */}
      {/* <td>{''}</td> */}

      {/* City */}
      <td>{donrCity}</td>

      {/* Amount */}
      <td>£{amount}</td>

      {/* Payment Method */}
      <td>
        {/* {props.dont.pm.m} */}
        <PaymentMethod pm={props.dont.pm.m} />
      </td>

      {/* Status */}
      <td>
        {/* {props.dont.s} */}
        <DontStatus s={props.dont.s} />
      </td>
    </tr>
  );
};

/**
 */
const PaymentMethod = (props) => {
  const [text, SetText] = React.useState("");

  React.useEffect(() => {
    if ("card" === props.pm) {
      SetText("Card");
    } else if ("bank" === props.pm) {
      SetText("Bank");
    } else if ("paypal" === props.pm) {
      SetText("PayPal");
    } else if ("wallet" === props.pm) {
      SetText("Wallet");
    } else if ("cash" === props.pm) {
      SetText("Cash");
    } else if ("cheque" === props.pm) {
      SetText("Cheque");
    } else {
      SetText(props.pm);
    }
  }, [props.pm]);

  return (
    <p
    // style={{backgroundColor: colour}}
    >
      {text}
    </p>
  );
}; // PaymentMethod

/**
 */
const DontStatus = (props) => {
  const [text, SetText] = React.useState("");
  const [colour, SetColour] = React.useState("");

  React.useEffect(() => {
    if ("new" === props.s) {
      SetText("Commited");
      SetColour("yellow");
    } else if ("received" === props.s) {
      SetText("Received");
    } else if ("refunded" === props.s) {
      SetText("Refunded");
      SetColour("lightblue");
    } else if ("init" === props.s) {
      SetText("Processing");
      SetColour("orange");
    } else if ("failed" === props.s) {
      SetText("Failed");
      SetColour("lightgrey");
    } else {
      SetText(props.s);
    }
  }, [props.s]);

  return <p style={{ backgroundColor: colour, padding: 5 }}>{text}</p>;
}; // DontStatus
