/**
 * @copyright KARD
 */

import React, {CSSProperties} from "react";

//  import {
//      Button,
//  } from '@material-ui/core'

// import { navigate } from "@reach/router";

// import Loader from "react-js-loader";

import { useCSVReader } from 'react-papaparse';

// import { connect } from "react-redux";
// import * as actions from "../../rdx/actions";

//  import {Time} from '../../api/Utils'

import Page from "../_common/page";
// import { Overlay } from "../../aui/loader";
// import Hdr from '../_common/hdr'
// import SideBar from '../_common/sidebar'
// import ChatNotif from '../_common/chat_notifications'

// import {ViewApntModal} from './appntModal'

const DonorBulk = (props) => {
    const [csvHdr, CsvHdr] = React.useState( [] )
    const [csvLines, CsvLines] = React.useState( [] )
    return (
        <Page active="address">
            <CsvFileReader
                OnRead={ (csv_lines) => {
                    console.log( 'OnRead: csv_lines: ', csv_lines )

                    const csv_hdr = csv_lines.splice( 0, 1 )

                    CsvHdr( csv_hdr.length ? csv_hdr[0] : [] )
                    CsvLines( csv_lines.length ? csv_lines : [] )
                } }
            />

            <div>
            {
                csvLines.length &&
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox" id="topping" name="all" value="all" />
                            </th>
                            <th>
                                {/* index */}
                            </th>
                            <>
                            {
                                csvHdr.map( (x, y) => (
                                    <th key={y}>{x}</th>
                                ) )
                            }
                            </>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        // csvLines.length
                    }
                    </tbody>
                </table>
            }
            </div>
        </Page>
    )
}

const CsvFileReader = (props) => {
    const { CSVReader } = useCSVReader();

    return (
        <CSVReader
            onUploadAccepted={(results: any) => {
                console.log('---------------------------');
                console.log(results);
                console.log('---------------------------');

                props.OnRead( results )
            }}
        >
        {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
        }: any) => (
            <>
            <div style={styles.csvReader}>
                <button type='button' {...getRootProps()} style={styles.browseFile}>
                Browse file
                </button>
                <div style={styles.acceptedFile}>
                {acceptedFile && acceptedFile.name}
                </div>
                <button {...getRemoveFileProps()} style={styles.remove}>
                Remove
                </button>
            </div>
            <ProgressBar style={styles.progressBarBackgroundColor} />
            </>
        )}
        </CSVReader>
    )
}

export default DonorBulk

const styles = {
    csvReader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },// as CSSProperties,
    browseFile: {
        width: '20%',
    },// as CSSProperties,
    acceptedFile: {
        border: '1px solid #ccc',
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: '80%',
    },// as CSSProperties,
    remove: {
        borderRadius: 0,
        padding: '0 20px',
    },// as CSSProperties,
    progressBarBackgroundColor: {
        backgroundColor: 'red',
    },// as CSSProperties,
};
