/**
 * @copyright KARD
 */

import React from "react";

import { navigate, Link } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import Page from "../_common/page";
import { LinearProgress } from "@material-ui/core";

/**
 */
const CampaignView = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  const [list, List] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  //
  React.useEffect(() => {
    Load(false).catch((err) => {});
  }, []);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.CrowdCampaign_List(b_more ? lastKey : null);

      console.log("CampaignView: Load: resp_list: ", resp_list);

      List(b_more ? [...list, ...resp_list.Items] : resp_list.Items);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("ProjectList: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  return (
    <Page active="crowd">
      {/* <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Crowd</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Campaign</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col text-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div> */}

      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/crowdactive.png")}
            />{" "}
            All Campaign
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row my-3 ml-1 mr-1">
              <div className="col-sm-4 col">
                {/* <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div> */}
              </div>
              {/* <div className="col-sm-8 col text-right">
                <button
                  className="btn btn btn-primary mr-1"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  data-whatever="@getbootstrap"
                >
                  <i className="fa fa-plus" /> Add New Campaign
                </button>
                <button className="btn btn btn-primary "> Upload Bulk</button>
              </div> */}
            </div>

            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Target</th>
                    <th>Raised</th>
                    <th>Not Received</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Project</th>
                    <th>Campaign</th>
                    {/* <th>Action</th> */}
                    <th>Status</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    <LinearProgress color="primary" />
                    <LinearProgress color="success" />
                    <LinearProgress color="inherit" />
                  </div>
                ) : null}
                <tbody>
                  {list.map((x) => (
                    <ListView key={x.id} camp={x} />
                  ))}
                </tbody>
              </table>
            </div>

            <div className="row">
              <div className="col-sm-12 ml-3 mr-3 mt-3">
                <div className="pull-left">
                  <div className="dataTables_length">
                    <tfoot>
                      {lastKey ? (
                        <button
                          className="btn btn-info"
                          onClick={() => {
                            Load(lastKey).catch((err) => {});
                          }}
                          disabled={isLoading}
                        >
                          Show More
                        </button>
                      ) : null}
                    </tfoot>
                  </div>
                </div>

                <div className="pull-right mr-5">
                  {/* <ul className="pagination">
                    <li className="">
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button previous"
                        id="DataTables_Table_0_previous"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="active">
                      <a tabindex="0">1</a>
                    </li>
                    <li>
                      <a tabindex="0">2</a>
                    </li>
                    <li>
                      <a tabindex="0">3</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button next"
                        id="DataTables_Table_0_next"
                      >
                        Next
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal start */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          {/* <NewPerson /> */}
        </div>
      </div>
      {/* End Modal */}
    </Page>
  );
}; // CampaignView

/**
 */

/**
 */
const ListView = (props) => {
  // const [campTitle, CampTitle] = React.useState( '' )

  // React.useState( () => {
  //   try{
  //     CampTitle( props.camp.ci.t )
  //   }
  //   catch( err ){}
  // } )

  return (
    <tr>
      <td>
        <a href="">20101</a>
      </td>
      <td>{props.camp.t}</td>
      <td>£{"100000.00"}</td>
      <td>£{"20000.00"}</td>
      <td>£{"20000.00"}</td>
      <td></td>
      <td></td>
      <td>{props.camp.pi.t}</td>
      <td>{props.camp.ci.t}</td>
      {/* <td>
        <a href="" className="btn btn-secondary btn-sm mr-1">
          <i className="fa fa-share text-white"></i>
        </a>
        <a href="" className="btn btn-primary btn-sm mr-1">
          <i className="fa fa-edit text-white"></i>
        </a>
        <a href="" className="btn btn-danger btn-sm">
          <i className="fa fa-trash text-white"></i>
        </a>
      </td> */}
      <td>
        <div className="switch_box box_1">
          <input type="checkbox" className="switch_1" disabled="" checked="" />
        </div>
      </td>
    </tr>
  );
};

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CampaignView);
