/**
 * @copyright KARD
 */

import React from "react";

import { navigate, Link } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

// import {
//     // Core,
//     DDB,
// } from '../../api/AWS'

// import Addr from '../../aui/addr'

import Page from "../_common/page";
import { LinearProgress } from "@material-ui/core";
import { Time } from "../../api/Utils";
import { useAlert } from "react-alert";

/**
 */
const NewsList = (props) => {
  const Alert = useAlert();

  const [isLoading, IsLoading] = React.useState(false);

  const [newsList, NewsList] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  //
  React.useEffect(() => {
    Load(null).catch((err) => {});
  }, []);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.News_List({
        last_key: b_more ? lastKey : null,
      });

      console.log("NewsList: Load: resp_list: ", resp_list);

      NewsList(b_more ? [...newsList, ...resp_list.Items] : resp_list.Items);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("NewsList: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load
  /**
   */
  const DeleteNews = async (post) => {
    try {
      const del_obj = {
        ni: post.ni,
      };
      console.log("del_obj", del_obj);

      const del_resp = await props.News_Delete(del_obj);
      console.log("news/index: DeleteNews: resp_save: ", del_resp);
      if (del_resp) {
        //
      }

      alert("News deleted ");

      const _list = newsList.filter((x) => x.ni !== post.ni);
      NewsList(_list);

      return {};
    } catch (err) {
      console.warn("news/index: Delete: err: ", err);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // DeleteNews
  //
  return (
    <Page active="news">
      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/news.png")}
            />{" "}
            News List
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row my-3 ml-1 mr-1">
              <div className="col-sm-4 col">
                {/* <button className="btn btn btn-primary mr-1">
                  <i className="fa fa-filter" />
                  <a href="" style={{ color: "#fff" }}>
                    Filter
                  </a>
                </button> */}
              </div>
              <div className="col-sm-8 col text-right">
                <button
                  onClick={() => navigate("/news/add")}
                  className="btn btn btn-primary float-right px-4 font-weight-bold"
                >
                  <i className="fa fa-plus" /> Add News
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Title</th>
                    <th>Project</th>
                    <th>Campaign</th>
                    {/* <th>Desc</th> */}
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                      }}
                    >
                      <LinearProgress color="primary" />
                      <LinearProgress color="success" />
                      <LinearProgress color="inherit" />
                    </div>
                  ) : null}
                  {newsList.map((x, i) => (
                    <NewsListView
                      key={x.ni}
                      news={x}
                      onDel={(post) => {
                        Alert.show("Do you want to delete this news ?", {
                          title: "Alert!!",
                          actions: [
                            {
                              copy: "Yes",
                              onClick: () => DeleteNews(post),
                            },
                          ],
                          closeCopy: "No",
                        });
                      }}
                    />
                    // <tr key={i}>
                    //     <td>{x.t}</td>
                    //     <td>{x.t}</td>
                    //     <td>{x.pr_t}</td>
                    //     <td>{x.d}</td>
                    //     <td>
                    //         <button>sss</button>
                    //     </td>
                    // </tr>
                  ))}
                </tbody>

                <tfoot>
                  {lastKey ? (
                    <button
                      onClick={() => {
                        Load(lastKey).catch((err) => {});
                      }}
                      disabled={isLoading}
                    >
                      Show More
                    </button>
                  ) : null}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // NewsList

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(NewsList);

/**
 */
const NewsListView = (props) => {
  return (
    <tr>
      <td>{Time.Epoch2Date(props.news.tn)}</td>
      <td className="text-primary" style={{ cursor: "pointer" }}>
        <a
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(`/news/view/${props.news.ni}`);
          }}
        >
          {props.news.t}
        </a>
      </td>
      <td>{props.news.pi.t}</td>
      <td>{props.news.ci.t}</td>
      <td style={{ display: "flex" }}>
        <a
          href=""
          className="btn btn-primary btn-sm mr-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(`/news/edit/${props.news.ni}`);
          }}
        >
          <i className="fa fa-edit text-white"></i>
        </a>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => {
            props.onDel(props.news);
          }}
        >
          <i className="fa fa-trash text-white"></i>
        </button>
      </td>
    </tr>
  );
};
