/**
 * @copyright KARD
 */

import React from "react";

//  import {
//      Button,
//  } from '@material-ui/core'

import { navigate } from "@reach/router";

import Loader from "react-js-loader";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

//  import {Time} from '../../api/Utils'

import Page from "../_common/page";
import { Overlay } from "../../aui/loader";
// import Hdr from '../_common/hdr'
// import SideBar from '../_common/sidebar'
// import ChatNotif from '../_common/chat_notifications'

// import {ViewApntModal} from './appntModal'

/**
 */
const StaffAdd = (props) => {
  const [isSubmitting, IsSubmitting] = React.useState(false);
  //
  const [userName, UserName] = React.useState("");
  const [password, Password] = React.useState("");
  const [name, Name] = React.useState({ t: "", f: "", l: "" });
  const [phone, Phone] = React.useState("");
  const [email, Email] = React.useState("");
  const [selectedRoles, SelectedRoles] = React.useState({});
  const [isModal_Success, IsModal_Success] = React.useState(false);
  const [error, set_Error] = React.useState(false);
  //
  const OnSubmit = (e) => {
    e.preventDefault();

    IsSubmitting(true);
    IsModal_Success(true);
    console.log("StaffAdd: userName: ", userName);

    const staff_obj = {
      u_id: userName,
      password: password,
      name: name,
      phone: phone,
      email: email,
      roles: props.__staffs.roles.reduce((a, c) => {
        if (selectedRoles[c.id]) {
          return [...a, c.id];
        } else {
          return a;
        }
      }, []),
    };

    console.log("UserAdd: OnSubmit: staff_obj: ", staff_obj);

    props
      .Staffs_Add(staff_obj)
      .then((data) => {
        IsSubmitting(false);

        UserName("");
        Name("");
        Phone("");
        Email("");
        SelectedRoles({});

        alert(data.pass);
      })
      .catch((err) => {
        IsSubmitting(false);
        IsModal_Success(false);
        set_Error(err.message ? err.message : err);
        // alert( err.message ? err.message : err )
      });
  }; // OnSubmit

  return (
    // console.log("error", error),
    (
      <Page active="staff">
        {isModal_Success && <Overlay />}
        <div className="row">
          <div className="col mb-3">
            <h4 className="projectTitle m-0 mb-4">
              <img
                style={{ width: 23, marginRight: 16 }}
                src={require("../../components/assets/icons/sideBar/active/staff.png")}
              />
              Add New Staff
            </h4>
          </div>
          {/*
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <button onClick={()=>navigate( "/membership/add")} className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus" /> Add New</button>
                </div>
                */}
        </div>

        <form onSubmit={OnSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label for="username">Username</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="John"
                  value={userName}
                  onChange={(e) => {
                    e.preventDefault();
                    UserName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label for="username">Password</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  value={password}
                  onChange={(e) => {
                    e.preventDefault();
                    Password(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label for="name">Title</label>
            <select
              className="form-control mb-3"
              value={name.t}
              onChange={(e) => {
                e.preventDefault();
                Name({ ...name, t: e.target.value });
              }}
              // required
            >
              <option value="">Select</option>
              <option value="Mr">Mr</option>
              <option value="Ms">Ms</option>
              <option value="Mrs">Mrs</option>
            </select>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label for="name">First Name</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="John"
                  value={name.f}
                  onChange={(e) => {
                    e.preventDefault();
                    Name({ ...name, f: e.target.value });
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label for="name">Last Name</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="White"
                  value={name.l}
                  onChange={(e) => {
                    e.preventDefault();
                    Name({ ...name, l: e.target.value });
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label for="email">Email</label>
                <input
                  type="email"
                  className="form-control mb-3"
                  placeholder="john@example.com"
                  value={email}
                  onChange={(e) => {
                    e.preventDefault();
                    Email(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              {" "}
              <div className="form-group">
                <label for="phone">Phone</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="+880 1777 777 777"
                  value={phone}
                  onChange={(e) => {
                    e.preventDefault();
                    Phone(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label for="roles">Assign Roles</label>
            <div>
              {props.__staffs.roles.map((x) => (
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    className="btn btn-outline-secondary"
                    style={{
                      color: selectedRoles[x.id] ? "#0094FF" : "#a8abaf",
                      borderColor: selectedRoles[x.id] ? "#0094FF" : "#dee2e6",
                      backgroundColor: selectedRoles[x.id]
                        ? "#EFF8FF"
                        : "white",
                      margin: "10px 10px 0 0 ",
                      padding: 10,
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      SelectedRoles({
                        ...selectedRoles,
                        [x.id]: !selectedRoles[x.id],
                      });
                    }}
                  >
                    {x.name}
                  </button>
                </div>
              ))}
            </div>
          </div>

          {error && (
            <div
              style={{
                borderRadius: 8,
                padding: 16,
                backgroundColor: "#fdeded",
              }}
            >
              <div
                style={{
                  color: "#5f2120",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {error}
              </div>
            </div>
          )}

          <div className="form-group" style={{ marginTop: 32 }}>
            <button type="submit" className="btn btn-primary">
              Create user
            </button>
          </div>
        </form>

        <>
        {
          (isSubmitting) &&
          <div>
            <div
                className="modal"
                style={{
                  // margin: '0 auto'
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#d1cccc80",
                }}
            >
            <Loader
              // type="box-rectangular"
              type="spinner-cub"
              bgColor={"#2680c2"}
              title={"Saving ..."}
              color={"#2680c2"}
              size={150}
            />
            </div>
          </div>
        }
        </>
      </Page>
    )
  );
}; // StaffAdd

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(StaffAdd);
