/**
 * @copyright KARD.
 */

import React from "react";
import { Table } from "react-bootstrap";
// import {
// 	Button,
// } from '@material-ui/core'

// import { navigate } from "@reach/router"

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import "./home.css";

/**
 */
const Campaigns = (props) => {
  const [isBusy, IsBusy] = React.useState(false);

  const [list, List] = React.useState([]);

  /**
   */
  React.useEffect(() => {
    Load().catch((err) => {});
  }, []);

  /**
   */
  const Load = async () => {
    try {
      IsBusy(true);

      const resp_list = await props.Campaign_List(null, 5);

      // console.log( 'home/Projects: Load: resp_list: ', resp_list )

      List(resp_list.Items);

      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("home/Projects: Load: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Load

  /**
   */
  return (
    <div>
      <h2 className="projectTitle" style={{ fontSize: 24, padding: "16px 0" }}>
        Recent Campaigns
      </h2>
      <div className="card">
        <Table className="table-striped" responsive style={{ border: 0 }}>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Target</th>
              <th>Raised</th>
              {/*<th style={{ width: 120 }}>Start / End</th>*/}
              <th>Project</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {list.map((x, idx) => (
              <ListView key={[x.i, x.tn].join(":")} idx={idx + 1} camp={x} />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}; // Campaigns

/**
 */
const ListView = (props) => {
  const [target, Target] = React.useState("0.00");
  const [raised, Raised] = React.useState("0.00");

  React.useEffect(() => {
    try {
      Target((props.camp.a.t ? props.camp.a.t : 0).toFixed(2));
    } catch (err) {
      //
    }

    try {
      Raised((props.camp.a.r ? props.camp.a.r : 0).toFixed(2));
    } catch (err) {
      //
    }
  }, []);

  return (
    <tr>
      <td>{props.idx}</td>
      <td>{props.camp.ci.t}</td>
      <td>{"£" + target}</td>
      <td>{"£" + raised}</td>
      {/*<td>{""}</td>*/}
      <td>{props.camp.pi.t}</td>
      <td>
        <div className="switch_box box_1">
          <input
            type="checkbox"
            className="switch_1"
            checked={"act" === props.camp.s}
            disabled
          />
        </div>
      </td>
    </tr>
  );
}; // ListView

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Campaigns);
