/**
 * @copyright KARD
 */

import React from "react";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

// import {
//     // Core,
//     DDB,
// } from '../../api/AWS'

// import Addr from '../../aui/addr'

import Page from "../_common/page";
import { LinearProgress } from "@material-ui/core";

/**
 */
const UsersList = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  React.useEffect(() => {
    IsLoading(true);

    props
      .Staffs_List()
      .then((data) => IsLoading(false))
      .catch((err) => {
        IsLoading(false);
        alert(err.message ? err.message : err);
      });
  }, []);

  const OnLoadMore = () => {
    IsLoading(true);

    props
      .Staffs_List(true)
      .then((data) => IsLoading(false))
      .catch((err) => {
        IsLoading(false);
        alert(err.message ? err.message : err);
      });
  }; // OnLoadMore

  return (
    <Page active="staff">
      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <img
              style={{ width: 26, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/staff.png")}
            />{" "}
            Staff List
          </h4>
        </div>

        {/* <div className="col-sm-8 col-9 text-right m-b-20">
                    <button onClick={()=>navigate( "/staff/add")} className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus" /> Add</button>
                </div> */}
      </div>

      <div className="row">{/*  */}</div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row my-3 ml-1 mr-1">
              <div className="col">
                {/* <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div> */}
              </div>
              <div className="col text-right">
                <button
                  onClick={() => navigate("/staff/add")}
                  className="btn btn btn-primary mr-1 px-4 font-weight-bold"
                >
                  <i className="fa fa-plus" /> Add New Staff
                </button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Roles</th>
                    {/* <th>Address</th> */}
                    {/* <th style={{ minWidth: 120 }}>Action</th> */}
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                      }}
                    >
                      <LinearProgress color="primary" />
                      <LinearProgress color="success" />
                      <LinearProgress color="inherit" />
                    </div>
                  ) : null}
                  {props.__staffs.list.map((x) => (
                    // <Store_ListView store={x} />
                    // null
                    <ListView key={[x.i, x.u].join(":")} staff={x} />
                    // <tr key={x.u_id}>
                    //     <td>{ x.name && [x.name.t, x.name.f, x.name.l].join( ' ' ) }</td>
                    //     <td>{ x.email }</td>
                    //     <td>{ x.phone }</td>
                    //     <td>{ x.roles && x.roles.join(', ') }</td>
                    //     {/* <th>{}</th> */}
                    //     <td>
                    //         <button
                    //             onClick={ e => {
                    //                 e.preventDefault()
                    //             } }
                    //         >
                    //             Edit
                    //         </button>
                    //         <button
                    //             onClick={ e => {
                    //                 e.preventDefault()
                    //             } }
                    //         >
                    //             Remove
                    //         </button>
                    //     </td>
                    // </tr>
                  ))}
                </tbody>

                <tfoot>
                  {
                    //     props.__staffs.list.length
                    // ?   <tr>
                    //         <th>Name</th>
                    //         <th>Email</th>
                    //         <th>Phone</th>
                    //         <th>Roles</th>
                    //         {/* <th>Address</th>
                    //         <th>Action</th> */}
                    //     </tr>
                    // :   null
                  }
                </tfoot>
              </table>

              <>
                {props.__staffs.lastKey ? (
                  <button onClick={OnLoadMore}>Load More</button>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // UsersList

/**
 */
const ListView = (props) => {
  const [strName, StrName] = React.useState("");
  const [strRoles, StrRoles] = React.useState("");

  React.useEffect(() => {
    try {
      StrName(
        [
          props.staff.n.t.charAt(0).toUpperCase() + props.staff.n.t.slice(1),
          props.staff.n.f,
          props.staff.n.l,
        ].join(" ")
      );
    } catch (err) {
      //
    }

    try {
      StrRoles(props.staff.r.join(", "));
    } catch (err) {
      //
    }
  }, []);

  return (
    <tr>
      <td>{strName}</td>
      <td>{props.staff.e}</td>
      <td>{props.staff.p}</td>
      <td className="text-capitalize">{strRoles}</td>
      {/* <td className="btn-group">
        <a
          className="btn btn-primary mr-2"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <i className="fa fa-edit text-white"></i>
        </a>
        <a
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <i className="fa fa-trash text-white"></i>
        </a>
      </td> */}
    </tr>
  );
}; // ListView

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(UsersList);
